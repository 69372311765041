import { netpost, post, get } from '../plugins/request'
import config from '../config/index';

export default {
  // 手机号证件号限制配置
  getLogisticsSettings(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/getLogisticsSettings`,params);
  },
  // 手机号证件号限制配置
  setLogisticsSettings(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/setLogisticsSettings`,params);
  },
  // 代理商手机号证件号限制配置
  getAgencyLogisticsSetting(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/getAgencyLogisticsSetting`,params);
  },
  // 代理商手机号证件号限制配置
  setAgencyLogisticsSettings(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/setAgencyLogisticsSettings`,params);
  },
}